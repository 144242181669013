.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    background: #eee;
}

.loading-container:before {
    content: "";
    height: 100%;
    display: inline-block;
    vertical-align: middle;
}

.loading-container .spinner-frame {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
}